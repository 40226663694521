<template>
<div class="container-fluid pb-5">
    <div class="row">
        <div class="col-12 pl-2 pr-4 pt-4 bg-accent">
            <div class="row px-2">

                <div class="col-lg-8">
                    <div class="toolbar mt-2">
                        <div class="btn-groupn" role="group" aria-label="Basic example">

                            <h3 class="vv" v-if="!$route.params.id">New Licence</h3>
                            <h3 class="vv" v-if="$route.params.id">Edit {{ licence.name }}</h3>
                        </div>
                    </div>
                </div>
                <div class="col text-right">

                    <button type="button" class="btn btn-mid btn-primary d-inline" @click="verify()">

                        <span class=" align-middle" v-if="$route.params.id">Save Changes</span>
                        <span class=" align-middle" v-else>Upload Licence</span>
                        <span v-show="saving" class="loading pl-1 my-auto pt-1 pull-right text-right"></span>

                    </button>

                </div>
            </div>
        </div>

        <div class="col-12 bg-accent border-bottom">
            <ul class="nav nav-tabs px-0 mx-0" id="myTab" role="tablist">
                <li class="nav-item">
                    <a class="nav-link active" id="home-tab" data-toggle="tab" href="#home" role="tab">Basic Info</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" id="meta-tab" data-toggle="tab" href="#customize" role="tab">Customize</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" id="meta-tab" data-toggle="tab" href="#edit" role="tab">Edit Licence</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" id="contact-tab" data-toggle="tab" href="#options" role="tab">Options</a>
                </li>
            </ul>
        </div>
        <div class="col-12" v-show="ready">

            <div class="tab-content" id="myTabContent">
                <div class="tab-pane fade show active px-2 pt-4" id="home" role="tabpanel" aria-labelledby="home-tab">
                    <div class="row">
                        <div class="col-lg-8">
                            <div class="row">
                                <div class="form-group col-12">
                                    <label> Name</label>
                                    <input v-model="licence.name" type="text" id="licencename" class="form-control" />
                                </div>
                                <div class="form-group col-12">
                                    <label> Price (USD $) </label>
                                    <input v-model="licence.price" type="number" class="form-control" id="beatname" />
                                </div>
                                <div class="form-group col-12">
                                    <label>Exclusive</label>

                                    <div class="switch_box box_1n">
                                        <input type="checkbox" class="switch_1" v-model="licence.exclusive" />
                                    </div>
                                </div>

                                <div class="col-12">
                                    <p>Files to Include</p>
                                    <hr />
                                </div>
                                <div class="form-group col-12">
                                    <label>Mp3 (Always Included)</label>

                                    <div class="switch_box box_1n">
                                        <input type="checkbox" class="switch_1 disabled" checked disabled />
                                    </div>
                                </div>
                                <div class="form-group col-12">
                                    <label>Wav</label>

                                    <div class="switch_box box_1n">
                                        <input type="checkbox" class="switch_1" v-model="licence.wav" />
                                    </div>
                                </div>
                                <div class="form-group col-12">
                                    <label>Stems</label>

                                    <div class="switch_box box_1n">
                                        <input type="checkbox" class="switch_1" v-model="licence.stems" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="tab-pane fade px-2 pt-4" id="customize" role="tabpanel">
                    <div class="row">
                        <div class="col-lg-8">
                            <div class="row">
                                <div class="form-group col-12">
                                    <label> Streams Allowed</label>
                                    <input v-model="licence.streams" type="text" class="form-control" />
                                </div>

                                <div class="form-group col-12">
                                    <label> Copies Distributed</label>
                                    <input v-model="licence.distribution" type="text" class="form-control" />
                                </div>
                                <div class="form-group col-12">
                                    <label> Non Profit Performances</label>
                                    <input v-model="licence.np_performance" type="text" class="form-control" />
                                </div>
                                <div class="form-group col-12">
                                    <label> For Profit Performances</label>
                                    <input v-model="licence.fp_performance" type="text" class="form-control" />
                                </div>
                                <div class="form-group col-12">
                                    <label>Monetized Videos</label>
                                    <input v-model="licence.mon_vid" type="text" class="form-control" />
                                </div>
                                <div class="form-group col-12">
                                    <label>Monetized Video Streams</label>
                                    <input v-model="licence.mon_vid_streams" type="text" class="form-control" />
                                </div>
                                <div class="form-group col-12">
                                    <label>Non-Monetized Video Streams</label>
                                    <input v-model="licence.non_mon_vid_streams" type="text" class="form-control" />
                                </div>
                                <div class="form-group col-12">
                                    <label>Radio Stations</label>
                                    <input v-model="licence.radio" type="text" class="form-control" />
                                </div>
                                <div class="form-group col-12">
                                    <label>Publishing Percentage</label>
                                    <input v-model="licence.publishing" type="number" class="form-control" />
                                </div>
                                <div class="form-group col-12">
                                    <label>Broadcasting Rights</label>

                                    <div class="switch_box box_1n">
                                        <input type="checkbox" class="switch_1" v-model="licence.broadcast" />
                                    </div>
                                </div>
                                <div class="form-group col-12">
                                    <label>Must Credit</label>

                                    <div class="switch_box box_1n">
                                        <input type="checkbox" class="switch_1" v-model="licence.credit" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="tab-pane fade px-2 pt-4" id="edit" role="tabpanel">
                    <div class="row">
                        <div class="col-lg-12">

                            <vue-editor v-model="licence.content" :editorToolbar="customToolbar"></vue-editor>

                        </div>
                    </div>
                </div>
                <div class="tab-pane fade px-2 pt-4" id="options" role="tabpanel">
                    <div class="row">
                        <div class="col-lg-8">
                            <div class="row">
                                <div class="form-group col-12">
                                    <label>Enabled</label>

                                    <div class="switch_box box_1n">
                                        <input type="checkbox" class="switch_1" v-model="licence.enabled" />
                                    </div>
                                </div>
                                <div class="form-group col-12">
                                    <label>Allow Offers</label>

                                    <div class="switch_box box_1n">
                                        <input type="checkbox" class="switch_1" v-model="licence.offers" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg-12 text-center pt-5 animate__animated animate__fadeIn" v-show="!ready">
            <span class="loading-lg  text-center mx-auto"></span>
            <br>
            <br>
            <span class="text-muted pt-5 mt-5">loading...</span>
        </div>
    </div>
</div>
</template>

<style>
@import "../assets/css/ql.css";
</style>

<script>
import Snackbar from "node-snackbar";
import "node-snackbar/dist/snackbar.css";
import {
    VueEditor
} from "vue2-editor";
export default {
    name: "LicencesNew",
    components: {
        VueEditor,
    },
    data() {
        return {
            ready: false,
            saving: false,
            validationErrors: [],
            customToolbar: [
                [{
                    'header': [1, 2, 3, 4, 5, 6, false]
                }],
                ["bold", "italic", "underline"],
                [{
                    'align': []
                }],
                [{
                    list: "ordered"
                }, {
                    list: "bullet"
                }]
            ],
            licence: {
                content: "",
                name: "",
                price: 15.95,
                exclusive: false,
                wav: false,
                stems: false,
                streams: "10,000",
                distribution: "10,000",
                np_performance: "10,000",
                fp_performance: "10,000",
                mon_vid: "1",
                mon_vid_streams: "10,000",
                non_mon_vid_streams: "10,000",
                broadcast: false,
                publishing: 50,
                radio: "4",
                offers: false,
                credit: true,
                discount: false,
                enabled: true
            }
        };
    },
    mounted() {
        //this.ready = false;

        this.get_licence();

        //$('#file_modal').modal('show')
    },
    methods: {
        get_licence() {
            if (this.$route.params.id) {
                var baseURI =
                    this.$baseurl + "/licences/" + this.$route.params.id;
                var config = {
                    Authorization: "Bearer " + this.$store.getters.getUser.token,
                };

                this.$http
                    .get(baseURI, {
                        headers: config,
                    })
                    .then((result) => {
                        if (result.data.message === "invalid token") {} else {}
                        if (result.data[0]) {
                            this.licence = result.data[0]
                        }

                        //this.keys = Object.keys(this.res[0]);
                    });
                this.ready = true;

            } else {
                this.ready = true;
            }
        },
        cancel() {
            this.$router.push("/d/licences");
        },
        verify() {
            var send = this.licence
            send.price = parseFloat(send.price)
            send.publishing = parseInt(send.publishing)
            console.log(send)
            var baseURI,
                op,
                msg = "";
            baseURI = this.$baseurl + "/licences";
            if (this.$route.params.id) {
                op = "update"
                msg = "licence successfully updated";
            } else {
                op = "upload"
                msg = "licence uploaded successfully ";
            }

            var config = {
                Authorization: "Bearer " + this.$store.getters.getUser.token,
            };
            if (op == "upload") {
                this.$http
                    .post(baseURI, send, {
                        headers: config,
                    })
                    .then((result) => {
                        this.process(result, msg)
                        //this.keys = Object.keys(this.res[0]);
                    });
            } else if (op == "update") {
                this.$http
                    .put(baseURI, send, {
                        headers: config,
                    })
                    .then((result) => {
                        this.process(result, msg)
                        //this.keys = Object.keys(this.res[0]);
                    });
            }
        },
        process(result, msg) {

            if (result.data.error) {
                this.validationErrors.push({
                    message: result.data.message,
                });
                Snackbar.show({
                    text: result.data.message,
                    customClass: "snack-danger",
                    actionText: "DISMISS",
                });
                this.saving = false
            } else {

                Snackbar.show({
                    text: msg,
                    customClass: "snack-success",
                    actionText: "OK",
                });
                this.saving = false
                this.$router.push("/d/licences");
            }
        },
    },
};
</script>

<style>
</style>
